import React, { memo, forwardRef } from 'react'
import Icon from '../src/Icon'

const svgPaths16 = [
  'M4.16 9.002H.977C.44 9.002 0 8.532 0 7.994c0-.537.44-.99.978-.99h3.18A3.01 3.01 0 016.995 5a3.01 3.01 0 012.839 2.004h2.98c-.898-.756-1.404-1.193-1.518-1.31a1.03 1.03 0 01-.293-.705c0-.538.454-.989.992-.989.274 0 .521.108.697.294.118.124 1.122 1.13 3.014 3.016a.96.96 0 01.293.684.975.975 0 01-.284.695l-3.018 3.027a.974.974 0 01-.694.284c-.553 0-1-.447-1-.985 0-.274.117-.545.293-.72l1.518-1.293H9.833A3.01 3.01 0 016.996 11 3.01 3.01 0 014.16 9.002z'
]
const svgPaths20 = [
  'M5.125 10.997H.976C.439 10.997 0 10.537 0 10c0-.536.44-.993.976-.993h4.148a4.002 4.002 0 017.752 0h3.776L14.293 6.69a.962.962 0 01-.285-.687c0-.537.46-1.001.996-1.001a.96.96 0 01.698.3l4.005 4.015c.176.176.293.41.293.683a.972.972 0 01-.283.693L15.702 14.7a.997.997 0 01-.698.297c-.537 0-.996-.453-.996-.99 0-.273.107-.517.283-.692l2.371-2.318h-3.787a4.002 4.002 0 01-7.75 0z'
]

export const FlowLinearIcon = memo(
  forwardRef((props, ref) => (
    <Icon
      svgPaths16={svgPaths16}
      svgPaths20={svgPaths20}
      ref={ref}
      name="flow-linear"
      {...props}
    />
  ))
)
