export default class InMemoryCache {
  public namespace?: string;
  private store: Record<string, string>;
  // @ts-ignore
  private length: number;

  constructor(namespace?: string) {
    this.namespace = namespace;
    this.length = 0;
    this.store = {};
  }

  clear(): void {
    this.length = 0;
    this.store = {};
  }

  getItem(key: string): string | null {
    if (key in this.store) {
      return this.store[key];
    }

    return null;
  }

  setItem(key: string, value: unknown): string {
    this.store[key] = JSON.stringify(value);
    this.length = Object.keys(this.store).length;

    return (this.store[key] = JSON.stringify(value));
  }

  removeItem(key: string): null {
    if (key in this.store) {
      delete this.store[key];
    }

    this.length = Object.keys(this.store).length;

    return null;
  }

  key(index: number): string | null {
    return Object.values(this.store)[index];
  }
}
